import { authGuard } from "@/shared/guards/authGuard";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { RouteConstants, RouteNameConstants } from "./router-constants";
import ThePrivateBase from "../common/components/private-base-component/ThePrivateBase.vue";
import PublicBaseComponent from "@/common/components/public-base-component/PublicBaseComponent.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: RouteConstants.LandingPage,
    name: RouteNameConstants.LandingPage,
    components: {
      public: PublicBaseComponent,
      publicBody: () => import("../views/landing/LandingView.vue"),
    },
  },
  {
    path: RouteConstants.GeenRechtenInformatiePage,
    name: RouteNameConstants.GeenRechtenInformatiePage,
    components: {
      public: PublicBaseComponent,
      publicBody: () => import("../views/geen-rechten/GeenRechtenView.vue"),
    },
  },
  {
    path: RouteConstants.RegistratiePage,
    name: RouteNameConstants.RegistratiePublicPage,
    components: {
      public: PublicBaseComponent,
      publicBody: () => import("../views/registratie/registratie-public-view/RegistratiePublicView.vue"),
    },
  },
  {
    path: RouteConstants.RegistratieSuccessPage,
    name: RouteNameConstants.RegistratieSuccessPublicPage,
    components: {
      public: PublicBaseComponent,
      publicBody: () => import("../views/registratie/registratie-success-view/RegistratieSuccessView.vue"),
    },
  },
  {
    path: RouteConstants.CallBackPage,
    name: RouteNameConstants.CallBackPage,
    components: {
      public: PublicBaseComponent,
      publicBody: () => import("../views/callback-acm-idm/CallBackAcmIdmView.vue"),
    },
  },
  {
    path: RouteConstants.NotFoundPage,
    name: RouteNameConstants.NotFoundPage,
    components: {
      public: PublicBaseComponent,
      publicBody: () => import("@/common/components/not-found-component/NotFoundComponent.vue"),
    },
  },
  {
    path: RouteConstants.UnauthorizedPage,
    name: RouteNameConstants.UnauthorizedPage,
    components: {
      public: PublicBaseComponent,
      publicBody: () => import("@/common/components/unauthorized-component/UnauthorizedComponent.vue"),
    },
  },
  {
    path: RouteConstants.StartPage,
    name: RouteNameConstants.StartPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/startpagina/startpagina-view/StartpaginaView.vue"),
    },
  },
  {
    path: `${RouteConstants.Private}${RouteConstants.RegistratiePage}`,
    name: RouteNameConstants.RegistratiePrivatePage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/registratie/registratie-private-view/RegistratiePrivateView.vue"),
    },
  },
  {
    path: `${RouteConstants.Private}${RouteConstants.RegistratieSuccessPage}`,
    name: RouteNameConstants.RegistratieSuccessPrivatePage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/registratie/registratie-success-view/RegistratieSuccessView.vue"),
    },
  },
  {
    path: RouteConstants.BeheerPage,
    name: RouteNameConstants.BeheerPage,
    redirect: { name: RouteNameConstants.BeheerBegunstigdenPage }
  },
  {
    path: RouteConstants.BegunstigdenPage,
    name: RouteNameConstants.BeheerBegunstigdenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/beheer/BeheerView.vue"),
    },
  },
  {
    path: RouteConstants.LeveranciersPage,
    name: RouteNameConstants.BeheerLeveranciersPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/beheer/BeheerView.vue"),
    },
  },
  {
    path: RouteConstants.SoorttypenPage,
    name: RouteNameConstants.BeheerSoorttypenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/beheer/BeheerView.vue"),
    },
  },
  {
    path: "/beheer/soorttype-toevoegen",
    name: RouteNameConstants.SoorttypeToevoegenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/soorttype/soorttype-create-view/SoorttypeCreateView.vue"),
    },
  },
  {
    path: "/beheer/soorttypen/:identifier",
    name: RouteNameConstants.SoorttypeDetailPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/soorttype/soorttype-detail-view/SoorttypeDetailView.vue"),
    },
  },
  {
    path: "/beheer/begunstigde-toevoegen",
    name: RouteNameConstants.BegunstigdeToevoegenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/begunstigde/begunstigde-create-view/BegunstigdeCreateView.vue"),
    },
  },
  {
    path: "/beheer/begunstigden/:identifier",
    name: RouteNameConstants.BegunstigdeDetailPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/begunstigde/begunstigde-detail-view/BegunstigdeDetailView.vue"),
    },
  },
  {
    path: "/beheer/leverancier-toevoegen",
    name: RouteNameConstants.LeverancierToevoegenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/leverancier/leverancier-create-view/LeverancierCreateView.vue"),
    },
  },
  {
    path: "/beheer/leveranciers/:identifier",
    name: RouteNameConstants.LeverancierDetailPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/leverancier/leverancier-detail-view/LeverancierDetailView.vue"),
    },
  },
  {
    path: RouteConstants.KadastraleToestandPage,
    name: RouteNameConstants.KadastraleToestandPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/kadastrale-toestand/kadastrale-toestand-list-view/KadastraleToestandListView.vue"),
    },
  },
  {
    path: "/kadastrale-toestand/toevoegen",
    name: RouteNameConstants.KadastraleToestandToevoegenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/kadastrale-toestand/kadastrale-toestand-create-view/KadastraleToestandCreateView.vue"),
    },
  },
  {
    path: RouteConstants.LeveringenPage,
    name: RouteNameConstants.LeveringenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/leveringen/levering-list-view/LeveringenView.vue"),
    },
  },
  {
    path: `${RouteConstants.LeveringenPage}/toevoegen`,
    name: RouteNameConstants.LeveringToevoegenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/leveringen/levering-create-view/LeveringCreateView.vue"),
    },
  },
  {
    path: "/kadastrale-toestand/:identifier",
    name: RouteNameConstants.KadastraleToestandDetailPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/kadastrale-toestand/kadastrale-toestand-detail-view/KadastraleToestandDetailView.vue"),
    },
  },
  {
    path: RouteConstants.ThemabestandPage,
    name: RouteNameConstants.ThemabestandPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/themabestand/rvv-record-list-view/RvvRecordListView.vue"),
    },
  },
  {
    path: RouteConstants.DownloadPage,
    name: RouteNameConstants.DownloadPage,
    redirect: { name: RouteNameConstants.DownloadVoorkooprechtenPage },
  },
  {
    path: RouteConstants.DownloadVoorkooprechtenPage,
    name: RouteNameConstants.DownloadVoorkooprechtenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/download/download-view/DownloadView.vue"),
    },
  },
  {
    path: RouteConstants.DownloadReferentieDataPage,
    name: RouteNameConstants.DownloadReferentieDataPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/download/download-view/DownloadView.vue"),
    },
  },
  {
    path: `${RouteConstants.DownloadPage}/voorkooprechten-download-aanvraag-toevoegen`,
    name: RouteNameConstants.DownloadVoorkooprechtenAanvraagPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/download/download-voorkooprechten-aanvraag-create-view/DownloadVoorkooprechtenAanvraagCreateView.vue"),
    },
  },
  {
    path: `${RouteConstants.PerceelHistoriekPage}`,
    name: RouteNameConstants.PerceelHistoriekPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/perceel-historiek/perceel-historiek-list-view/PerceelHistoriekListView.vue"),
    },
  },
  {
    path: `${RouteConstants.InstellingenPage}`,
    name: RouteNameConstants.InstellingenPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/instellingen/InstellingenView.vue"),
    },
  },
  {
    path: `${RouteConstants.DashboardPage}`,
    name: RouteNameConstants.DashboardPage,
    beforeEnter: authGuard,
    components: {
      private: ThePrivateBase,
      privateBody: () => import("../views/dashboard/dashboard-view/DashboardView.vue"),
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: RouteNameConstants.NotFoundPage }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
