<script>
import Vue from "vue";
import RvvToaster from "@/common/components/rvv-toaster-component/RvvToasterComponent.vue";
import { useCurrentContextStore } from "@/stores/currentContextStore";
import { storeToRefs } from "pinia";
import { securityService } from "@/shared/security/security-service";
import { RouteConstants } from "./router/router-constants";
import FooterComponent from "@/common/components/footer-component/FooterComponent.vue";
import RvvToasterSuccessComponent from "@/common/components/rvv-toaster-success-component/RvvToasterSuccessComponent.vue";

export default Vue.extend({
  components: {
    FooterComponent,
    RvvToaster,
    RvvToasterSuccessComponent
  },
  computed: {
    isPublicPage() {
      return [
        RouteConstants.LandingPage,
        RouteConstants.CallBackPage,
        RouteConstants.NotFoundPage,
        RouteConstants.UnauthorizedPage,
        RouteConstants.GeenRechtenInformatiePage,
        RouteConstants.RegistratiePage,
        RouteConstants.RegistratieSuccessPage,
      ].includes(this.$route.path);
    },
  },
  async mounted() {
    const currentContextStore = useCurrentContextStore();
    await currentContextStore.fetchVersie();
  },
  data: function () {
    return {
      get userIsLoggedIn() {
        return securityService.hasBearerToken;
      },
    };
  },
  setup() {
    const currentContextStore = useCurrentContextStore();
    const { isLoading, hasHttpError, versie, sperperiodeInformatie } = storeToRefs(currentContextStore);

    return { isLoading, hasHttpError, versie, sperperiodeInformatie };
  },

  watch: {
    isLoading(newVal) {
      if (newVal) {
        this.$Progress.start();
      } else {
        this.$Progress.finish();
      }
    },
    hasHttpError(newVal) {
      if (newVal) {
        this.$Progress.fail();
      }
    },
  },
});
</script>

<template>
  <div id="app">
    <div class="rvv-content">
      <vue-progress-bar></vue-progress-bar>
      <rvv-toaster />
      <rvv-toaster-success-component />
      <vl-page>
        <template v-if="userIsLoggedIn && !isPublicPage">
          <router-view name="private" />
          <vl-layout v-if="sperperiodeInformatie">
            <vl-alert
              icon="warning"
              :content="$i18n.t('global.sperperiode-active', { jaar: sperperiodeInformatie.kadastraleToestandJaar, begindatum: sperperiodeInformatie.begindatum, einddatum: sperperiodeInformatie.einddatum, begindatumActief: sperperiodeInformatie.earliestBegindatumVoorkooprechten })"
              mod-warning
              role="alert">
            </vl-alert>
          </vl-layout>
          <vl-region>
            <vl-layout>
              <router-view name="privateBody"></router-view>
            </vl-layout>
          </vl-region>
        </template>

        <template v-if="isPublicPage">
          <router-view name="public" />
          <div id="main" itemprop="mainContentOfPage" role="main" tabindex="-1" class="vl-main-content">
            <vl-region>
              <vl-layout>
                <router-view name="publicBody"></router-view>
              </vl-layout>
            </vl-region>
          </div>
        </template>
      </vl-page>
    </div>
    <div class="rvv-footer">
      <footer-component :versie="versie"></footer-component>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  position: relative;
  min-height: 100vh;
}

.rvv-content {
  padding-bottom: 11.5rem;    /* Footer height */
}

.rvv-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;            /* Footer height */
}
</style>
