import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { isEmpty } from "@/common/utils/typescript-utilities";

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)

export const DEFAULT_LOCALE = 'nl';

export const DATE_FORMAT_DEFAULTS = {
  displayDateOnlyFormat: "DDMMYYYY",
  displayDateOnlySlashFormat: "DD/MM/YYYY",
  displayDateTimeSlashFormat: "DD/MM/YYYY HH:mm:ss",
  dateTimeFormat: "YYYY-MM-DDTHH:mm:ssZ"
};



export default class DateAdapter {
  static isValidDateString(dateString: string, inputFormat: string): boolean {
    if (dateString.length !== inputFormat.length) return false;

    const date = this.toDayjsDate(dateString, inputFormat);
    return date.isValid();
  }

  static toDateStringOrDefault(dateString: string | null | undefined, inputFormat: string, outputFormat = DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat): string | undefined {
    if(isEmpty(dateString)) return undefined;

    const isValid = this.isValidDateString(dateString as string, inputFormat);
    if (!isValid) return undefined;

    const date = this.toDayjsDate(dateString as string, inputFormat);

    return date.format(outputFormat);
  }

  static toLocalDateStringOrDefault(dateString: string | null | undefined, inputFormat: string, outputFormat = DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat): string | undefined {
    if(isEmpty(dateString)) return undefined;

    const isValid = this.isValidDateString(dateString as string, inputFormat);
    if (!isValid) return undefined;

    const date = this.toDayjsDate(dateString as string, inputFormat);

    return date.local().format(outputFormat);
  }

  static toDateTimeOffsetStringOrDefault(dateString: string | null | undefined, inputFormat: string): string | undefined {
    return this.toDateStringOrDefault(dateString, inputFormat, DATE_FORMAT_DEFAULTS.dateTimeFormat);
  }

  static toDayjsDate(value: string, format: string, strict = true): Dayjs {
    if(format === DATE_FORMAT_DEFAULTS.dateTimeFormat) return dayjs(value);

    return dayjs(value, format, strict);
  }

  static nowAsLocalDateString(format: string = DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat): string {
    return dayjs().local().format(format);
  }

  static isGreaterThanOrEqual(value: Dayjs, compare: Dayjs): boolean {
    return value.isSameOrAfter(compare, "day");
  }

  static isBeforeThanOrEqual(value: Dayjs, compare: Dayjs): boolean {
    return value.isSame(compare, "day") || value.isBefore(compare, "day");
  }

  static isGreaterThan(value: Dayjs, compare: Dayjs): boolean {
    return value.isAfter(compare);
  }

  static toDateTimeEndOfDayFormat(dateString: string | null | undefined, inputFormat: string = DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat): string | undefined {
    switch (inputFormat) {
      case DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat: // DD/MM/YYYY
        return `${dateString} 23:59:59`;
        break;
    }
  }

  static getLatestDate(dateA: string, dateB: string): string{
    const dateADayjs = this.toDayjsDate(dateA, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
    const dateBDayjs = this.toDayjsDate(dateB, DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
    if(this.isGreaterThan(dateADayjs, dateBDayjs)) return dateADayjs.format(DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);

    return dateBDayjs.format(DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat);
  }
}
