import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia, PiniaVuePlugin } from "pinia";
import Maska from 'maska'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueProgressBar from 'vue-progressbar'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {defaultValue, formatDate, split, truncate, formatDateTime, prefix} from "./shared/pipes";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VlUiVueComponents from "@govflanders/vl-ui-vue-components";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18n from "./i18n/i18n";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import validators from "./shared/validation-rules";

import "./assets/scss/vl-styles.scss";
import "./assets/scss/rvv-styles.scss";

import NavBarComponent from "@/modules/navbar/components/navbar-component/NavbarComponent.vue";
// import SortableTableHeaderComponent from "@/common/components/tables/SortableTableHeaderComponent.vue";
// import PaginatorComponent from "@/common/components/tables/PaginatorComponent.vue";
import RouterLinkTileComponent from "@/common/components/router-link-tile-component/RouterLinkTileComponent.vue";
import VerticalUploadFormComponent from "@/common/components/forms/vertical-upload-form-component/VerticalUploadFormComponent.vue";
import VerticalTextInputFormComponent from "@/common/components/forms/vertical-text-input-form-component/VerticalTextInputFormComponent.vue";
import VerticalDatepickerFormComponent from "@/common/components/forms/vertical-datepicker-form-component/VerticalDatepickerFormComponent.vue";
import VerticalCheckboxFormComponent from "@/common/components/forms/vertical-checkbox-form-component/VerticalCheckboxFormComponent.vue";
import VerticalRadioListFormComponent from "@/common/components/forms/vertical-radio-list-form-component/VerticalRadioListFormComponent.vue";
import VerticalDropdownFormComponent from "@/common/components/forms/vertical-dropdown-form-component/VerticalDropdownFormComponent.vue";
import VerticalAutocompleteFormComponent from "@/common/components/forms/vertical-autocomplete-form-component/VerticalAutocompleteFormComponent.vue";
import MessageModalComponent from "@/common/components/modals/message-modal-component/MessageModalComponent.vue";
import ConfirmCancelModalComponent from "@/common/components/modals/confirm-cancel-modal-component/ConfirmCancelModalComponent.vue";
import RvvSearchComponent from "@/common/components/rvv-search-component/RvvSearchComponent.vue";
import VerticalTextComponent from "@/common/components/vertical-text/vertical-text-component/VerticalTextComponent.vue";
import HorizontalTextComponent from "@/common/components/horizontal-text/horizontal-text-component/HorizontalTextComponent.vue";
import HorizontalRadioListFormComponent from "@/common/components/forms/horizontal-radio-list-form-component/HorizontalRadioListFormComponent.vue";

import { jwtInterceptor } from "./shared/interceptors/jwtInterceptor";
import { errorInterceptor } from "./shared/interceptors/errorInterceptor";
import { httpInterceptor } from "./shared/interceptors/httpInterceptor";

import '@/common/class-component-hooks';

import dayjs from "dayjs";
import { DEFAULT_LOCALE } from "./shared/date-adapter";

require('dayjs/locale/nl')
dayjs.locale(DEFAULT_LOCALE);

// filters
Vue.filter("defaultValue", defaultValue);
Vue.filter("formatDate", formatDate);
Vue.filter("formatDateTime", formatDateTime);
Vue.filter("split", split);
Vue.filter('truncate', truncate);
Vue.filter('prefix', prefix);

// pinia
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// validators
Vue.use(validators);

// vl ui components
const validatorConfig = {
  inject: true,
  locale: "nl",
};

Vue.use(VlUiVueComponents, {
  validation: validatorConfig,
});

// custom components
Vue.component("nav-bar-component", NavBarComponent);
// Vue.component("sortable-table-header-component", SortableTableHeaderComponent);
// Vue.component("paginator-component", PaginatorComponent);
Vue.component("router-link-tile-component", RouterLinkTileComponent);
Vue.component("vertical-text-input-form-component", VerticalTextInputFormComponent);
Vue.component("vertical-datepicker-form-component", VerticalDatepickerFormComponent);
Vue.component("vertical-checkbox-form-component", VerticalCheckboxFormComponent);
Vue.component("message-modal-component", MessageModalComponent);
Vue.component("confirm-cancel-modal-component", ConfirmCancelModalComponent);
Vue.component("vertical-upload-component", VerticalUploadFormComponent);
Vue.component("vertical-dropdown-form-component", VerticalDropdownFormComponent);
Vue.component("vertical-radio-list-form-component", VerticalRadioListFormComponent);
Vue.component("horizontal-radio-list-form-component", HorizontalRadioListFormComponent);
Vue.component("vertical-autocomplete-form-component", VerticalAutocompleteFormComponent);

Vue.component("vertical-text-component", VerticalTextComponent);
Vue.component("horizontal-text-component", HorizontalTextComponent);

Vue.component("rvv-search", RvvSearchComponent);

Vue.config.productionTip = false;

// mask
Vue.use(Maska);

// Progress bar
const options = {
  color: '#0056CC',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
  autoFinish: false
}

Vue.use(VueProgressBar, options);

// interceptors
httpInterceptor();
jwtInterceptor();
errorInterceptor();

new Vue({
  router,
  i18n,
  pinia,
  render: (h) => h(App)
}).$mount("#app");
